<template>
  <div>
    <v-row>
      <v-col lg="8" offset-lg="2">
        <v-card>
          <v-card-title>
            <v-icon class="pe-3"> mdi-airplane-cog</v-icon>
            Settings
            <v-spacer />
            <v-btn
              color="primary"
              rounded
              elevation="0"
              to="/admin/airfield/edit"
            >
              <v-icon>mdi-edit</v-icon>
              Edit Airfield
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col lg="8" offset-lg="2">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-icon class="pe-3"> mdi-headset</v-icon>
                Operation Mode
              </v-card-title>
              <v-card-text>
                <v-radio-group v-model="operationMode">
                  <v-radio value="attended" class="py-3">
                    <template v-slot:label>
                      <div>
                        <h3>
                          Attended
                          <span
                            v-if="
                              operationMode === 'attended' &&
                              saveStateOperationMode
                            "
                            class="d-inline-block ms-2 text-body-1 green--text"
                          >
                            {{
                              saveStateOperationMode === "saved"
                                ? "Saved"
                                : "Saving..."
                            }}
                            <v-icon
                              v-if="saveStateOperationMode === 'saved'"
                              small
                              color="green"
                              style="vertical-align: baseline"
                            >
                              mdi-check
                            </v-icon>
                          </span>
                        </h3>
                        <p class="my-2">
                          For each arrival/departure you will approve and mark
                          as arrived/departed, for full control over your
                          operation.
                        </p>
                        <p class="mb-0 text-body-2">
                          Usually used at large airfields where occasional PPR
                          requests may be declined during very busy periods.
                        </p>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="auto-approve" class="py-3">
                    <template v-slot:label>
                      <div>
                        <h3>
                          Auto Approve
                          <span
                            v-if="
                              operationMode === 'auto-approve' &&
                              saveStateOperationMode
                            "
                            class="d-inline-block ms-2 text-body-1 green--text"
                          >
                            {{
                              saveStateOperationMode === "saved"
                                ? "Saved"
                                : "Saving..."
                            }}
                            <v-icon
                              v-if="saveStateOperationMode === 'saved'"
                              small
                              color="green"
                              style="vertical-align: baseline"
                            >
                              mdi-check
                            </v-icon>
                          </span>
                        </h3>
                        <p class="my-2">
                          PPR requests will be automatically approved upon
                          submission, but you will still mark
                          arrivals/departures as arrived and departed.
                        </p>
                        <p class="mb-0 text-body-2">
                          Usually used at Air/Ground airfields with a radio
                          operator who can manage arrivals and departures.
                        </p>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="unattended" class="py-3">
                    <template v-slot:label>
                      <div>
                        <h3>
                          Unattended
                          <span
                            v-if="
                              operationMode === 'unattended' &&
                              saveStateOperationMode
                            "
                            class="d-inline-block ms-2 text-body-1 green--text"
                          >
                            {{
                              saveStateOperationMode === "saved"
                                ? "Saved"
                                : "Saving..."
                            }}
                            <v-icon
                              v-if="saveStateOperationMode === 'saved'"
                              small
                              color="green"
                              style="vertical-align: baseline"
                            >
                              mdi-check
                            </v-icon>
                          </span>
                        </h3>
                        <p class="my-2">
                          To collect arrival and departure logs and landing fee
                          payments, without any interaction with pilots.
                        </p>
                        <p class="mb-0 text-body-2">
                          Usually used at private airfields without a radio
                          operator or anyone on-site to monitor operations.
                        </p>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <validation-observer ref="observer">
              <v-form
                ref="form"
                :disabled="saveStateRunwayConfig === 'saving'"
                lazy-validation
                @submit.prevent="onSaveRunwayConfig"
              >
                <v-card>
                  <v-card-title>
                    <v-icon class="pe-3"> mdi-road-variant</v-icon>
                    Runways
                    <span
                      v-if="saveStateRunwayConfig"
                      class="d-inline-block ms-3 text-body-1 green--text"
                    >
                      {{
                        saveStateRunwayConfig === "saved"
                          ? "Saved"
                          : "Saving..."
                      }}
                      <v-icon
                        v-if="saveStateRunwayConfig === 'saved'"
                        small
                        color="green"
                        style="vertical-align: baseline"
                      >
                        mdi-check
                      </v-icon>
                    </span>
                    <v-spacer />
                    <v-btn
                      title="Insert new rule below"
                      color="primary"
                      fab
                      x-small
                      elevation="0"
                      @click="onInsertRunway()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="runwayConfig.runways.length > 0">
                    <runway
                      v-for="(runway, i) in runwayConfig.runways"
                      :key="i"
                      :index="i"
                      :model="runway"
                      :on-delete="onDeleteRunway"
                    />
                  </v-card-text>
                  <v-card-text v-else>
                    Tap the plus button above to add a runway.
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      title="Save"
                      color="secondary"
                      class="mx-2"
                      @click="onSaveRunwayConfig()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </validation-observer>
          </v-col>

          <v-col cols="12">
            <validation-observer ref="observer">
              <v-form
                ref="form"
                :disabled="saveStateWeatherConfig === 'saving'"
                lazy-validation
                @submit.prevent="onSaveWeatherConfig"
              >
                <v-card>
                  <v-card-title>
                    <v-icon class="pe-3"> mdi-weather-sunny</v-icon>
                    Weather
                    <span
                      v-if="saveStateWeatherConfig"
                      class="d-inline-block ms-3 text-body-1 green--text"
                    >
                      {{
                        saveStateWeatherConfig === "saved"
                          ? "Saved"
                          : "Saving..."
                      }}
                      <v-icon
                        v-if="saveStateWeatherConfig === 'saved'"
                        small
                        color="green"
                        style="vertical-align: baseline"
                      >
                        mdi-check
                      </v-icon>
                    </span>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="length:4"
                        >
                          <v-text-field
                            outlined
                            label="METAR Station ICAO Code"
                            hint="Airfield code for METAR weather, 4 character code assigned by ICAO"
                            persistent-hint
                            v-model="weatherConfig.icaoCode"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|integer"
                        >
                          <v-text-field
                            label="Airfield Elevation (ft)"
                            hint="Your airfield's elevation, used to calculate pressure at your airfield (QFE)."
                            persistent-hint
                            v-model.number="weatherConfig.airfieldElevation"
                            type="number"
                            required
                            :error-messages="errors"
                            outlined
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      title="Save"
                      color="secondary"
                      class="mx-2"
                      @click="onSaveWeatherConfig()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </validation-observer>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { integer, length, required } from "vee-validate/dist/rules";
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import Runway from "@/components/settings/Runway.vue";

export default {
  created() {
    this.loadFromDatabase();

    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required",
    });
    extend("length", {
      ...length,
      message: "Must be {length} characters",
    });
    extend("integer", {
      ...integer,
      message: "Must be a whole number",
    });
  },

  components: {
    Runway,
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    operationMode: "attended",
    saveStateOperationMode: null,
    runwayConfig: {
      active: {
        number: "",
        circuitDirection: "",
        lastUpdatedAt: "",
      },
      runways: [],
    },
    saveStateRunwayConfig: null,
    weatherConfig: {
      icaoCode: "",
      airfieldElevation: 0,
    },
    saveStateWeatherConfig: null,
  }),

  computed: {
    ...mapGetters({
      dbOperationMode: "auth/operationMode",
      dbRunwayConfig: "auth/runwayConfig",
      dbWeatherConfig: "auth/weatherConfig",
    }),
  },

  methods: {
    loadFromDatabase() {
      this.operationMode = this.dbOperationMode;
      this.runwayConfig = this.dbRunwayConfig;
      this.weatherConfig = this.dbWeatherConfig;
    },

    async onInsertRunway() {
      if (!this.runwayConfig.active) {
        this.runwayConfig.active = {};
      }
      if (!this.runwayConfig.runways) {
        this.runwayConfig.runways = [];
      }
      this.runwayConfig.runways.push({ number: "", circuitDirection: "" });
    },

    async onDeleteRunway(index) {
      this.runwayConfig.runways.splice(index, 1);
    },

    async onSaveRunwayConfig() {
      this.saveStateRunwayConfig = "saving";
      this.$refs.observer
        .validate()
        .then(async (isValid) => {
          if (isValid) {
            await this.$store.dispatch(
              "auth/saveRunwayConfig",
              this.runwayConfig
            );
          }
        })
        .catch((e) => {
          console.error("Cannot save runway_data", e);
        })
        .finally(() => {
          this.saveStateRunwayConfig = "saved";
        });
    },

    async onSaveWeatherConfig() {
      this.saveStateWeatherConfig = "saving";
      this.$refs.observer
        .validate()
        .then(async (isValid) => {
          if (isValid) {
            await this.$store.dispatch(
              "auth/saveWeatherConfig",
              this.weatherConfig
            );
          }
        })
        .finally(() => {
          this.saveStateWeatherConfig = "saved";
        });
    },
  },

  watch: {
    async operationMode(operationMode) {
      this.saveStateOperationMode = "saving";
      await this.$store.dispatch("auth/saveOperationMode", operationMode);
      this.saveStateOperationMode = "saved";
    },
  },
};
</script>
