<template>
  <div>
    <v-row>
      <v-col cols="12" xs="6" sm="4" md="3">
        <validation-provider v-slot="{ errors }" rules="required">
          <v-text-field
            label="Number"
            persistent-hint
            placeholder="06, 24L, or 18R"
            v-model="model.number"
            required
            :error-messages="errors"
            outlined
            dense
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" xs="6" sm="4" md="3">
        <validation-provider v-slot="{ errors }" rules="required">
          <v-select
            label="Circuit Direction"
            persistent-hint
            :items="circuitDirections"
            v-model="model.circuitDirection"
            required
            :error-messages="errors"
            outlined
            dense
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" xs="6" sm="4" md="3">
        <validation-provider v-slot="{ errors }" rules="required">
          <v-select
            label="Surface"
            persistent-hint
            :items="surfaceTypes"
            v-model="model.surfaceType"
            required
            :error-messages="errors"
            outlined
            dense
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" xs="6" sm="4" md="3" lg="2" class="ml-auto text-right">
        <v-btn
          title="Delete this runway"
          color="red darken-3"
          dark
          fab
          x-small
          elevation="0"
          class="d-inline-flex ms-auto"
          @click="onDelete(index)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, setInteractionMode } from "vee-validate";

export default {
  name: "Runway",
  props: {
    index: { type: Number, required: true },
    model: { type: Object, required: true },
    onDelete: { type: Function, required: true },
  },

  data: () => ({
    circuitDirections: [
      { value: "LH", text: "Left Hand" },
      { value: "RH", text: "Right Hand" },
    ],
    surfaceTypes: [
      { value: "Grass", text: "Grass" },
      { value: "Asphalt", text: "Asphalt" },
      { value: "Concrete", text: "Concrete" },
    ],
  }),

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required",
    });
  },

  components: {
    ValidationProvider,
  },
};
</script>
